import logo from './logo.svg';
import './App.css';




function App() {
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className = "flex flex-row items-center justify-center">
      <img src = "/assets/gangnamsnake.gif"/>
      <div>
      <p>welome 2 hecking snecc</p>
      <p> he dance for u </p>
      </div>
      </div>
    </div>
  );
}

export default App;
